<template>
  <v-container fluid>

<!--    <v-snackbar-->
<!--        v-model="snackbar"-->
<!--        :timeout="2500"-->
<!--        right-->
<!--        top-->
<!--        color="green darken-2"-->
<!--    >-->
<!--      Copied : {{ snackbarText }}-->
<!--    </v-snackbar>-->
<!--    <v-row-->
<!--        justify="start"-->
<!--        align="start"-->
<!--        width="100%"-->
<!--        class="pl-3 pr-4 pt-3"-->
<!--    >-->
<!--      <v-row>-->
<!--        <v-col cols="12" sm="2" md="1">-->
<!--          <v-text-field-->
<!--              v-model="flowId"-->
<!--              dense-->
<!--              hide-details-->
<!--              label="ID"-->
<!--              type="string"-->
<!--              clearable-->
<!--          ></v-text-field>-->
<!--        </v-col>-->


<!--        <v-col cols="12" sm="1" md="1">-->
<!--          <v-autocomplete-->
<!--              v-model="affId"-->
<!--              :items="affs"-->
<!--              dense-->
<!--              filled-->
<!--              hide-details-->
<!--              label="Aff"-->
<!--              flat-->
<!--              solo-->
<!--              clearable-->
<!--          ></v-autocomplete>-->
<!--        </v-col>-->
<!--        <v-col cols="12" sm="2" md="2">-->
<!--          <v-autocomplete-->
<!--              v-model="whoAdd"-->
<!--              :items="users"-->
<!--              dense-->
<!--              filled-->
<!--              hide-details-->
<!--              label="Who add"-->
<!--              flat-->
<!--              solo-->
<!--              clearable-->
<!--          ></v-autocomplete>-->
<!--        </v-col>-->
<!--        <v-col cols="12" sm="2" md="2">-->
<!--          <v-autocomplete-->
<!--              v-model="creator"-->
<!--              :items="users"-->
<!--              dense-->
<!--              filled-->
<!--              hide-details-->
<!--              label="Creator"-->
<!--              flat-->
<!--              solo-->
<!--              clearable-->
<!--          ></v-autocomplete>-->
<!--        </v-col>-->
<!--        <v-col cols="12" sm="1" md="1">-->
<!--          <v-autocomplete-->
<!--              v-model="offerName"-->
<!--              :items="offers"-->
<!--              dense-->
<!--              filled-->
<!--              hide-details-->
<!--              label="Offer"-->
<!--              flat-->
<!--              solo-->
<!--              clearable-->
<!--          ></v-autocomplete>-->
<!--        </v-col>-->
<!--        <v-col cols="12" sm="1" md="1">-->
<!--          <v-autocomplete-->
<!--              v-model="countryCode"-->
<!--              :items="countryCodes"-->
<!--              dense-->
<!--              filled-->
<!--              hide-details-->
<!--              label="GEO"-->
<!--              flat-->
<!--              solo-->
<!--              clearable-->
<!--          ></v-autocomplete>-->
<!--        </v-col>-->


<!--        <v-col cols="12" sm="6" md="3">-->
<!--          <v-pagination v-model="page" :length="pageCount" total-visible="7"></v-pagination>-->
<!--        </v-col>-->

<!--        <v-col cols="12" sm="1" md="1">-->
<!--          <v-select-->
<!--              class="pt-1 mr-0"-->
<!--              v-model="itemsPerPage"-->
<!--              :items="itemsPerPageOptions"-->
<!--              hide-details-->
<!--              dense-->
<!--              solo-->
<!--          ></v-select>-->
<!--        </v-col>-->
<!--      </v-row>-->
<!--    </v-row>-->
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-data-table
            fixed-header
            :headers="headers"
            :items="tasks"
            :loading="loading"
            :server-items-length="total"
            :options.sync="options"
            :page.sync="page"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            multi-sort
            item-key="_id"
            hide-default-footer
            @page-count="pageCount = $event"
            @click:row="onClickRow"
        >

          <template v-if="itemsPerPage > 10" v-slot:footer>
            <v-row
                justify="start"
                align="start"
                width="100%"
                class="pl-3 pr-4"
            >
              <v-row>
                <v-col cols="12" sm="6" offset-md="9" md="3">
                  <v-pagination v-model="page" :length="pageCount" total-visible="7"></v-pagination>
                </v-col>
              </v-row>
            </v-row>
          </template>

          <template v-slot:item.model="{ item }">
            {{ getModel(item) }}
          </template>

          <template v-slot:item._id="{ item }">
            <v-hover v-slot:default="{ hover }">
              <div>
                {{ item._id }}
                <v-icon small :style="{ visibility: hover ? 'visible': 'hidden' }"
                        @click.stop="copyToClipboard(item._id)">
                  mdi-content-copy
                </v-icon>
              </div>
            </v-hover>
          </template>

          <template v-slot:item.source="{ item }">
            {{ item.source ? item.source.replace('DSP_', '') : 'Mgid' }}
          </template>


          <template v-slot:item.country_code="{ item }">
            <flag :iso="item.country_code" class="mr-3"/>
          </template>

          <template v-slot:item.@timestamp="{ item }">
            {{ dateFormat(item['@timestamp']) }}
          </template>

          <template v-slot:item.offer_name="{ item }">
            <div v-if="item.postfix"> {{ item['offer_name'] + ' ( ' + item.postfix + ' )' }}</div>
            <div v-else> {{ item['offer_name'] }}</div>

          </template>

        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from '@/utils/api'

export default {
  name: 'Tasks',
  data () {
    return {
      itemsPerPageOptions: [5, 10, 15, 20, 30, 50, 100],

      page: 1,
      pageCount: 0,
      options: {itemsPerPage: 10},

      total: 0,
      tasks: [],

      loading: false,
      itemsPerPage: 10,
      sortBy: ['lastUpdate'],
      sortDesc: [true],
      headers: [
        {text: 'ID', align: 'start', value: 'id', class: 'sticky-header', sortable: false},
        {text: 'Updated', align: 'start', value: 'lastUpdate', class: 'sticky-header', sortable: false},
        {text: 'Section', align: 'start', value: 'section', class: 'sticky-header', sortable: false},
        {text: 'Status', align: 'start', value: 'status', class: 'sticky-header', sortable: true},
        {text: 'User', align: 'start', value: 'user_name', class: 'sticky-header', sortable: true},
        {text: 'data', align: 'start', value: 'data', class: 'sticky-header', sortable: false},
        {text: 'talk', align: 'start', value: 'talk', class: 'sticky-header', sortable: false},
        // {text: 'Model', align: 'start', value: 'model', class: 'sticky-header'},
        // {text: 'Source', align: 'start', value: 'source', class: 'sticky-header'},
        // {text: 'Aff', align: 'start', value: 'aff_id', class: 'sticky-header'},
        // {text: 'Offer ID', align: 'start', value: 'offer_id', class: 'sticky-header'},
        // {text: 'User', align: 'start', value: 'user_id', class: 'sticky-header'},
        // {text: 'Creator', align: 'start', value: 'creator', class: 'sticky-header'},
        // {text: 'Offer Name', align: 'start', value: 'offer_name', class: 'sticky-header'},
        // {text: 'GEO', align: 'start', value: 'country_code', class: 'sticky-header'},
        // {text: 'Created', align: 'start', value: '@timestamp', class: 'sticky-header'}
      ],
    }
  },
  mounted () {
    this.getDataFromApi()
  },
  methods: {
    async getDataFromApi () {
      this.loading = true

      const sortBy = this.sortBy.length > 0 ? this.sortBy : undefined
      const sortDesc = this.sortDesc.length > 0 ? this.sortDesc : undefined

      const request = {
        limit: this.itemsPerPage,
        page: this.page - 1,
        sortBy,
        sortDesc
      }
      try {
        const resp = await api.tasksList(request)

        console.info('tasksList', resp.data)

        this.$set(this, 'tasks', resp.data.tasks)
        this.$set(this, 'total', resp.data.total)

        // console.table( r.data.flows)
        // this.$set(this, 'flows', resp.data.flows.map((f) => ({_id: f._id, ...f._source})))
        // this.$set(this, 'total', resp.data.total)

        // this.$set(this, 'countryCodes', resp.data.countryCodes)
        // this.$set(this, 'users', resp.data.users)
        // this.$set(this, 'affs', resp.data.affs)
        // this.$set(this, 'offers', resp.data.offers)
        //
        // const respCatalog = await api.flowCatalog()
        // // console.info('flowCatalog', respCatalog.data)
        //
        // this.countriesAll = respCatalog.data.countries
        // this.usersAll = respCatalog.data.users
        // this.affiliateNetworks = respCatalog.data.affiliateNetworks
        // this.dspList = respCatalog.data.dspList

        this.loading = false

      } catch (e) {
        console.error('getDataFromApi', e)
        this.loading = false
      }
    },

    copyToClipboard() {

    },

    onClickRow() {

    }
  }
}
</script>

<style scoped>

</style>